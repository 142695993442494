<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="기계분류"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :selection="popupParam.type"
      :isExcelDown="false"
      :isExpan="false"
      :isFullScreen="false"
      :columnSetting="false"
      gridHeight="640px"
      rowKey="hhmHazardousMachineryId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
            <c-btn label="선택" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['inspectionSubjectFlag']">
          <q-chip outline square
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            :class="props.row.inspectionSubjectFlag ==='A' ? 'blinking' : null"
            >
            <q-icon v-if="props.row.inspectionSubjectFlag==='A'" name="alarm" style="margin-right:5px" />
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "hazard-equipment-class-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        inspectionSubjectFlag: null,
        resultCd: null,
        gradeCd: null,
        inspectionKindCd: null,
        inspectionCycle: null,
        departmentDeptCd: '',
        departmentUserId: '',
        startYmd: '',
        endYmd: '',
        useFlag: 'Y'
      },
      period: ['', ''],
      editable: true,
      colorItems: [],
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch:{
  },
  methods: {
    init() {
      this.colorItems = [
        { stepperMstCd: 'Y', stepperMstNm: this.$language('대상'), colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: this.$language('비대상'), colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: this.$language('지연'), colorClass: 'red' },
      ]
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.hhm.hazard.check.list.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      this.getList();
      this.setHeader()
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        console.log(_result.data)
      },);
    },
    setHeader() {
      this.grid.columns = [
        {
          name: "plantName",
          field: "plantName",
          label: "사업장",
          style: 'width:80px', 
          align: "center",
          sortable: true,
        },
        {
          name: "machineryName",
          field: "machineryName",
          label: "기계명",
          style: 'width:200px', 
          align: "left",
          sortable: true,
          type: "link",
        },
        {
          name: "inspectionSubjectFlag",
          field: "inspectionSubjectFlag",
          label: "검사상태",
          type: 'custom',
          align: "center",
          sortable: true,
          style: 'width:100px',
        },
        {
          name: "itemNo",
          field: "itemNo",
          label: "item 번호",
          style: 'width:120px', 
          align: "center",
          sortable: true,
        },
        {
          name: "machineryClassificationName",
          field: "machineryClassificationName",
          label: "기계분류",
          style: 'width:160px', 
          align: "left",
          sortable: true,
        },
        {
          name: "amount",
          field: "amount",
          label: "수량",
          align: "center",
          sortable: true,
          style: 'width:40px', 
        },
        {
          name: "departmentDeptName",
          field: "departmentDeptName",
          label: "담당부서",
          style: 'width:120px', 
          align: "left",
          sortable: true,
        },
        {
          name: "departmentUserName",
          field: "departmentUserName",
          label: "담당자",
          align: "center",
          sortable: true,
          style: 'width:80px', 
        },
        {
          name: "resultCd",
          field: "resultCd",
          label: "검사결과",
          type: 'tag',
          colorItems: [
            { stepperMstCd: 'HRC000005', stepperMstNm: this.$language('합격'), colorClass: 'blue' },
            { stepperMstCd: 'HRC000010', stepperMstNm: this.$language('불합격'), colorClass: 'red' },
          ],
          align: "center",
          sortable: true,
          style: 'width:100px',
        },
        {
          name: "gradeName",
          field: "gradeName",
          label: "등급",
          align: "center",
          style: 'width:60px', 
          sortable: true,
        },
        {
          name: "inspectionKindName",
          field: "inspectionKindName",
          label: "검사종류",
          align: "center",
          sortable: true,
          style: 'width:100px', 
        },
        {
          name: "inspectionCycleName",
          field: "inspectionCycleName",
          label: "검사주기",
          align: "center",
          sortable: true,
          style: 'width:90px',
        },
        {
          name: "lastInspectionDate",
          field: "lastInspectionDate",
          label: "최근검사일",
          align: "center",
          sortable: true,
          style: 'width:90px',
        },
        {
          name: "nextInspectionDate",
          field: "nextInspectionDate",
          label: "차기검사일",
          align: "center",
          sortable: true,
          style: 'width:90px',
        },
        {
          name: "inspCertificateIssuanceDate",
          field: "inspCertificateIssuanceDate",
          label: "검사증교부일",
          align: "center",
          sortable: true,
          style: 'width:90px',
        },
      ]
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.target = () => import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () => import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '유해위험기계기구 상세'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '유해위험기계기구를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
<style>
.inspectionSubjectFlag-popblinking {
  -webkit-animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
  -moz-animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
  animation:inspectionSubjectFlag-popblink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes inspectionSubjectFlag-popblink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>